import React from 'react'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import colors from 'style/colors'
import shadows from 'style/shadows'
import chorusIllustration from 'assets/images/chorus.svg'
import Button, {
  styles as buttonStyles,
  mobileButtonResponsiveStyles,
  normalButtonResponsiveStyles,
} from 'components/Button'
import comparisonTableAnswerImage500 from 'assets/images/comparison-table-blurred-500w.jpg'
import comparisonTableAnswerImage1000 from 'assets/images/comparison-table-blurred-1000w.jpg'
import comparisonTableAnswerImage1500 from 'assets/images/comparison-table-blurred-1500w.jpg'
import balanceAnswerImage from 'assets/images/faq-balance-video-answer.svg'
/* eslint-disable known-imports/no-unused-vars */
import balanceVideo from 'assets/videos/faq-video-en-1080p.mp4'
import balanceVideoSpanish from 'assets/videos/faq-video-es-1080p.mp4'
/* eslint-enable known-imports/no-unused-vars */
import balanceVideoCaptionsSpanish from 'assets/videos/faq-video-captions-es.vtt'
import faqBalanceVideoCaptionsEsVietnamese from 'assets/videos/faqBalance-es-vi.vtt'
import faqBalanceVideoCaptionsEsFarsi from 'assets/videos/faqBalance-es-fa.vtt'
import faqBalanceVideoCaptionsEsTagalog from 'assets/videos/faqBalance-es-tl.vtt'
import faqBalanceVideoCaptionsEsRussian from 'assets/videos/faqBalance-es-ru.vtt'
import faqBalanceVideoCaptionsEsPortuguese from 'assets/videos/faqBalance-es-pt.vtt'
import faqBalanceVideoCaptionsEsKorean from 'assets/videos/faqBalance-es-ko.vtt'
import faqBalanceVideoCaptionsEsHindi from 'assets/videos/faqBalance-es-hi.vtt'
import faqBalanceVideoCaptionsEsGerman from 'assets/videos/faqBalance-es-de.vtt'
import faqBalanceVideoCaptionsEsFrench from 'assets/videos/faqBalance-es-fr.vtt'
import faqBalanceVideoCaptionsEsArabic from 'assets/videos/faqBalance-es-ar.vtt'
import balanceVideoCaptions from 'assets/videos/faq-video-captions-en.vtt'
import faqBalanceVideoCaptionsEnVietnamese from 'assets/videos/faqBalance-en-vi.vtt'
import faqBalanceVideoCaptionsEnFarsi from 'assets/videos/faqBalance-en-fa.vtt'
import faqBalanceVideoCaptionsEnTagalog from 'assets/videos/faqBalance-en-tl.vtt'
import faqBalanceVideoCaptionsEnRussian from 'assets/videos/faqBalance-en-ru.vtt'
import faqBalanceVideoCaptionsEnPortuguese from 'assets/videos/faqBalance-en-pt.vtt'
import faqBalanceVideoCaptionsEnKorean from 'assets/videos/faqBalance-en-ko.vtt'
import faqBalanceVideoCaptionsEnHindi from 'assets/videos/faqBalance-en-hi.vtt'
import faqBalanceVideoCaptionsEnGerman from 'assets/videos/faqBalance-en-de.vtt'
import faqBalanceVideoCaptionsEnFrench from 'assets/videos/faqBalance-en-fr.vtt'
import faqBalanceVideoCaptionsEnArabic from 'assets/videos/faqBalance-en-ar.vtt'
import balanceVideoThumbnail from 'assets/images/faq-video-thumbnail.png'
import avatarGladys from 'assets/images/patient-story-avatar-gladys.svg'
import avatarLouis from 'assets/images/patient-story-avatar-louis.svg'
import avatarMarisa from 'assets/images/patient-story-avatar-marisa.svg'
import avatarJames from 'assets/images/patient-story-avatar-james.svg'
import avatarBruce from 'assets/images/patient-story-avatar-bruce.svg'
import avatarLarry from 'assets/images/patient-story-avatar-larry.svg'
import avatarBarbara from 'assets/images/patient-story-avatar-barbara.svg'
import avatarSusan from 'assets/images/patient-story-avatar-susan.svg'
import avatarMaria from 'assets/images/patient-story-avatar-maria.svg'
import {imageSourcePropType} from 'util/propTypes'
import {ReactComponent as FaqReverseEffectsIcon} from 'assets/images/faq-reverse-effects.svg'
import {ReactComponent as FaqCostIcon} from 'assets/images/faq-cost.svg'
import {ReactComponent as FaqAspirinIcon} from 'assets/images/faq-aspirin.svg'
import {ReactComponent as FaqInjuredIcon} from 'assets/images/faq-injured.svg'
import {ReactComponent as FaqSurgeryIcon} from 'assets/images/faq-surgery.svg'
import {ReactComponent as FaqSeriousIcon} from 'assets/images/faq-serious.svg'
import {ReactComponent as FaqHowLongIcon} from 'assets/images/faq-how-long.svg'
import {ReactComponent as FaqCanTakeIcon} from 'assets/images/faq-can-take.svg'
import {ReactComponent as FaqRiskyActivityIcon} from 'assets/images/faq-risky-activity.svg'
import {ReactComponent as FaqEcgIcon} from 'assets/images/faq-ecg.svg'
import {ReactComponent as FaqWhichThinnerIcon} from 'assets/images/faq-which-thinner.svg'
import {ReactComponent as FaqOtherOptionsIcon} from 'assets/images/faq-other-options.svg'
import {ReactComponent as FaqRiskIcon} from 'assets/images/faq-risk.svg'
import {ReactComponent as RightCaretIcon} from 'assets/images/right-caret-blue.svg'
import BackButton from 'components/BackButton'
import {mq} from 'style/mediaQueries'
import AudioHighlight from 'components/AudioHighlight'
import {ClinicianBloodThinnerResponsiveTable} from 'pages/ClinicianBloodThinnerTable'
import Image from 'components/Image'

export const questions = [
  {
    key: 'balance',
    video: true,
    image: chorusIllustration,
    imageAspectRatio: 3733 / 1080,
    answers: [
      {
        content: [
          {
            type: 'video',
            image: balanceAnswerImage,
            imageAspectRatio: 1635 / 850,
            idKey: 'faqBalance',
            alt: '',
            fallbackCacheKey: 'faqVideo',
            fallbackCacheCaptionsUrl: {
              en: {
                en: balanceVideoCaptions,
                ar: faqBalanceVideoCaptionsEnArabic,
                fr: faqBalanceVideoCaptionsEnFrench,
                de: faqBalanceVideoCaptionsEnGerman,
                hi: faqBalanceVideoCaptionsEnHindi,
                ko: faqBalanceVideoCaptionsEnKorean,
                pt: faqBalanceVideoCaptionsEnPortuguese,
                ru: faqBalanceVideoCaptionsEnRussian,
                tl: faqBalanceVideoCaptionsEnTagalog,
                fa: faqBalanceVideoCaptionsEnFarsi,
                vi: faqBalanceVideoCaptionsEnVietnamese,
              },
              es: {
                es: balanceVideoCaptionsSpanish,
                ar: faqBalanceVideoCaptionsEsArabic,
                fr: faqBalanceVideoCaptionsEsFrench,
                de: faqBalanceVideoCaptionsEsGerman,
                hi: faqBalanceVideoCaptionsEsHindi,
                ko: faqBalanceVideoCaptionsEsKorean,
                pt: faqBalanceVideoCaptionsEsPortuguese,
                ru: faqBalanceVideoCaptionsEsRussian,
                tl: faqBalanceVideoCaptionsEsTagalog,
                fa: faqBalanceVideoCaptionsEsFarsi,
                vi: faqBalanceVideoCaptionsEsVietnamese,
              },
            },
            fallbackCacheThumbnail: balanceVideoThumbnail,
            hasSpanishVersion: true,
          },
        ],
      },
      {
        content: [
          {
            key: 'hearFromOthers',
          },
        ],
      },
      {
        content: [
          {
            type: 'patientStories',
            questionKey: 'strokeQuestion',
            stories: [
              {
                nameKey: 'gladys',
                avatar: avatarGladys,
                key: 'storyGladys',
                numSentences: 3,
              },
              {
                nameKey: 'louis',
                avatar: avatarLouis,
                key: 'storyLouis',
                numSentences: 3,
              },
            ],
          },
        ],
      },
      {
        content: [
          {
            type: 'patientStories',
            questionKey: 'bleedingIncidentQuestion',
            stories: [
              {
                nameKey: 'marisa',
                avatar: avatarMarisa,
                key: 'storyMarisa',
                numSentences: 6,
              },
              {
                nameKey: 'james',
                avatar: avatarJames,
                key: 'storyJames',
                numSentences: 3,
              },
            ],
          },
        ],
      },
      {
        content: [
          {
            type: 'patientStories',
            questionKey: 'takeQuestion',
            stories: [
              {
                nameKey: 'bruce',
                avatar: avatarBruce,
                key: 'storyBruce',
                numSentences: 5,
              },
              {
                nameKey: 'barbara',
                avatar: avatarBarbara,
                key: 'storyBarbara',
                numSentences: 6,
              },
            ],
          },
        ],
      },
      {
        content: [
          {
            type: 'patientStories',
            questionKey: 'balanceQuestion',
            stories: [
              {
                nameKey: 'susan',
                avatar: avatarSusan,
                key: 'storySusan',
                numSentences: 3,
              },
              {
                nameKey: 'larry',
                avatar: avatarLarry,
                key: 'storyLarry',
                numSentences: 4,
              },
              {
                nameKey: 'maria',
                avatar: avatarMaria,
                key: 'storyMaria',
                numSentences: 2,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'canReverse',
    icon: <FaqReverseEffectsIcon />,
    answers: [
      {
        content: [
          {
            key: 'badInjury',
          },
        ],
      },
    ],
  },
  {
    key: 'cost',
    icon: <FaqCostIcon />,
    answers: [
      {
        content: [
          {
            key: 'price',
          },
          {
            key: 'inGeneral',
          },
          {
            key: 'monitoring',
          },
        ],
      },
    ],
  },
  {
    key: 'aspirin',
    icon: <FaqAspirinIcon />,
    answers: [
      {
        content: [
          {
            key: 'protect',
          },
          {
            key: 'common',
          },
        ],
      },
    ],
  },
  {
    key: 'injured',
    icon: <FaqInjuredIcon />,
    answers: [
      {
        content: [
          {
            type: 'listItem',
            key: 'minor',
          },
          {
            type: 'listItem',
            key: 'calm',
          },
          {
            type: 'listItem',
            key: 'pressure',
          },
          {
            type: 'listItem',
            key: 'aboveHeart',
          },
          {
            type: 'listItem',
            key: 'hold',
          },
        ],
      },
    ],
  },
  {
    key: 'surgery',
    icon: <FaqSurgeryIcon />,
    answers: [
      {
        content: [
          {
            key: 'instructions',
          },
        ],
      },
    ],
  },
  {
    key: 'serious',
    icon: <FaqSeriousIcon />,
    answers: [
      {
        content: [
          {
            key: 'er',
          },
          {
            type: 'listItem',
            key: 'severePain',
          },
          {
            type: 'listItem',
            key: 'autoAccident',
          },
          {
            key: 'call',
          },
          {
            type: 'listItem',
            key: 'persistentPain',
          },
          {
            type: 'listItem',
            key: 'bleedingContinues',
          },
          {
            key: 'callDoctor',
          },
        ],
      },
    ],
  },
  {
    key: 'howLong',
    icon: <FaqHowLongIcon />,
    answers: [
      {
        content: [
          {
            key: 'recommended',
          },
        ],
      },
    ],
  },
  {
    key: 'canTake',
    icon: <FaqCanTakeIcon />,
    answers: [
      {
        content: [
          {
            key: 'most',
          },
          {
            key: 'important',
          },
        ],
      },
    ],
  },
  {
    key: 'riskyActivity',
    icon: <FaqRiskyActivityIcon />,
    answers: [
      {
        content: [
          {
            key: 'check',
          },
        ],
      },
    ],
  },
  {
    key: 'ecg',
    icon: <FaqEcgIcon />,
    answers: [
      {
        content: [
          {
            key: 'check',
          },
        ],
      },
    ],
  },
  {
    key: 'whichThinner',
    icon: <FaqWhichThinnerIcon />,
    answers: [
      {
        content: [
          {
            key: 'choice',
          },
          {
            type: 'listItem',
            key: 'choiceWarfarin',
          },
          {
            type: 'nestedListItem',
            key: 'regularBloodTests',
          },
          {
            type: 'nestedListItem',
            key: 'dietaryChanges',
          },
          {
            type: 'listItem',
            key: 'thinnersList',
          },
          {
            type: 'nestedListItem',
            key: 'recommended',
          },
          {
            type: 'nestedListItem',
            key: 'notAdjusted',
          },
          {
            type: 'nestedListItem',
            key: 'lowerRisk',
          },
          {
            type: 'nestedListItem',
            key: 'cost',
          },
        ],
      },
      {
        content: [
          {
            key: 'dontMiss',
          },
        ],
      },
      {
        content: [
          {
            key: 'tableBelow',
          },
          {
            type: 'image',
            imageSizes: '(min-width: 544px) 500px, 92vw',
            imageSrcSet: {
              500: comparisonTableAnswerImage500,
              1000: comparisonTableAnswerImage1000,
              1500: comparisonTableAnswerImage1500,
            },
            expandedContent: <ClinicianBloodThinnerResponsiveTable />,
            alt: '',
            idKey: 'bloodThinnerComparisonTable',
          },
        ],
      },
    ],
  },
  {
    key: 'otherOptions',
    icon: <FaqOtherOptionsIcon />,
    answers: [
      {
        content: [
          {
            key: 'watchman',
          },
        ],
      },
    ],
  },
  {
    key: 'risk',
    icon: <FaqRiskIcon />,
    answers: [
      {
        content: [
          {
            key: 'scoringSystem',
          },
          {
            type: 'listItem',
            key: 'twoPoints',
          },
          {
            type: 'nestedListItem',
            key: 'age75',
          },
          {
            type: 'nestedListItem',
            key: 'priorStroke',
          },
          {
            type: 'listItem',
            key: 'onePoint',
          },
          {
            type: 'nestedListItem',
            key: 'heartFailure',
          },
          {
            type: 'nestedListItem',
            key: 'diabetes',
          },
          {
            type: 'nestedListItem',
            key: 'highBloodPressure',
          },
          {
            type: 'nestedListItem',
            key: 'vascularDisease',
          },
          {
            type: 'nestedListItem',
            key: 'age65',
          },
          {
            type: 'nestedListItem',
            key: 'female',
          },
          {
            key: 'addUp',
          },
          {
            type: 'listItem',
            key: 'scoreZero',
          },
          {
            type: 'listItem',
            key: 'scoreOne',
          },
          {
            type: 'listItem',
            key: 'scoreTwo',
          },
        ],
      },
    ],
  },
]

const Question = flow(
  addTranslationHelpers,
  ({text, index, icon, image, imageAspectRatio, audioClipKey, t}) => (
    <li css={styles.questionItemContainer}>
      <Button
        mode="plain"
        css={[
          styles.questionContainer,
          index === questions.length - 1 && styles.questionContainerLast,
          !!image && styles.questionContainerImage,
        ]}
        linkTo={`/common-questions/${index + 1}`}
      >
        <>
          <div css={styles.question}>
            {icon && <div css={styles.iconContainer}>{icon}</div>}
            <span css={styles.questionText}>
              <AudioHighlight clipKey={`${audioClipKey}-question`}>
                {index + 1}. {text}
              </AudioHighlight>
            </span>
            <RightCaretIcon css={styles.caret} />
          </div>
          {image && (
            <div css={styles.imageContainer}>
              <Image
                src={image}
                css={styles.image}
                alt=""
                aspectRatio={imageAspectRatio}
                ensureImageStretchesFullyAcrossContainer
              />

              <p css={styles.viewAnswer}>{t('faqQuestionsPage.viewAnswer')}</p>
            </div>
          )}
        </>
      </Button>
    </li>
  )
)

Question.propTypes = {
  text: PropTypes.string.isRequired,
  image: imageSourcePropType,
  imageAspectRatio: PropTypes.number,
  index: PropTypes.number.isRequired,
  icon: PropTypes.node,
  audioClipKey: PropTypes.string.isRequired,
}

const FaqQuestions = flow(
  addTranslationHelpers,
  ({t}) => (
    <Page
      css={styles.container}
      background={{mobile: colors.paleBlueBackground, tablet: colors.lightBlue}}
      audioSectionId="faqQuestionsPage"
      name="FAQ list"
    >
      <BackButton />
      <h1 css={styles.header}>
        <AudioHighlight clipKey="header">
          {t('faqQuestionsPage.header', {count: questions.length})}
        </AudioHighlight>
      </h1>
      <ul css={styles.questionsContainer}>
        {questions.map((question, index) => (
          <Question
            {...question}
            text={t(`faqQuestions.${question.key}.question`)}
            audioClipKey={question.key}
            index={index}
          />
        ))}
      </ul>
      <Button
        css={styles.skipButton}
        mode="primary"
        title={t(`faqQuestionsPage.skipButton`)}
        linkTo="/steps/3"
        data-testid="faq-questions-skip-button"
      />
    </Page>
  )
)

export default FaqQuestions

const styles = {
  container: {
    [mq.mobile]: {
      paddingLeft: 21,
      paddingRight: 23,
    },
    [mq.tablet]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [mq.desktop]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    paddingTop: 138,
    paddingBottom: 98,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    [mq.mobile]: {
      marginBottom: 42,
    },
    [mq.tablet]: {
      marginBottom: 60,
    },
    fontSize: 42,
    fontWeight: 500,
    color: colors.black,
  },
  questionContainer: {
    [mq.mobile]: {
      borderStyle: 'solid',
      borderColor: colors.borderGrey,
      borderWidth: 0,
      borderTopWidth: 1,
    },
    [mq.tablet]: {
      backgroundColor: colors.white,
      borderRadius: 20,
      boxShadow: shadows.card,
      marginBottom: 16,
      borderTopWidth: 0,
    },
  },
  questionContainerLast: {
    [mq.mobile]: {
      borderBottomWidth: 1,
      marginBottom: 31,
    },
    [mq.tablet]: {
      marginBottom: 16,
    },
  },
  question: {
    [mq.mobile]: {
      paddingLeft: 5,
      paddingRight: 12,
      paddingTop: 18,
      paddingBottom: 16,
    },
    [mq.tablet]: {
      paddingLeft: 20,
      paddingRight: 40,
      paddingTop: 4,
      paddingBottom: 4,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 120,
  },
  iconContainer: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.mobileMax]: {
      display: 'flex',
      transform: 'scale(0.7)',
      marginRight: 10,
      marginLeft: -11,
    },
    [mq.tablet]: {
      transform: 'none',
      marginRight: 24,
      marginLeft: 0,
    },
    width: 75,
    flexShrink: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  questionText: {
    fontSize: 26,
    fontWeight: 500,
    lineHeight: '36px',
    color: colors.black,
    textAlign: 'left',
    marginRight: 16,
  },
  caret: {
    flexShrink: 0,
    marginLeft: 'auto',
  },
  imageContainer: {
    position: 'relative',
  },
  image: {
    [mq.mobile]: {
      borderRadius: 20,
      marginBottom: 10,
    },
    [mq.mobileMax]: {
      marginBottom: 0,
    },
    [mq.tablet]: {
      borderRadius: '0 0 20px 20px',
    },
    display: 'block',
    width: '100%',
  },
  viewAnswer: {
    ...buttonStyles.button,
    ...buttonStyles.primary,
    [mq.mobile]: {
      ...mobileButtonResponsiveStyles,
      alignSelf: 'flex-start',
    },
    [mq.mobileMax]: {
      position: 'absolute',
      bottom: 14,
      left: 19,
    },
    [mq.tablet]: {
      bottom: 25,
      left: 25,
      ...normalButtonResponsiveStyles,
    },
  },
  skipButton: {
    alignSelf: 'flex-end',
    marginTop: 22,
    paddingLeft: 42,
    paddingRight: 42,
  },
  questionContainerImage: {
    [mq.mobile]: {
      paddingBottom: 15,
    },
    [mq.mobileMax]: {
      paddingBottom: 10,
    },
    [mq.tablet]: {
      paddingBottom: 0,
      marginBottom: 38,
    },
  },
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  questionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}
