import React from 'react'
import {addHandlers, flowMax} from 'ad-hok'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import i18n from 'i18next'
import {flow} from 'lodash/fp'

import IntroPage from 'components/IntroPage'
import AudioHighlight from 'components/AudioHighlight'
import {addTranslationHelpers, addCurrentLanguage} from 'util/i18n'
import Link from 'components/Link'
import addResetApp from 'util/addResetApp'
import {addLogTrackingEvent, addTrackingProps} from 'util/tracking'

const resources = [
  {
    translationKey: 'whatIs',
    urls: {
      en:
        'https://www.heart.org/-/media/data-import/downloadables/e/3/5/pe-abh-what-is-atrial-fibrillation-ucm_300294.pdf',
      es:
        'https://www.heart.org/-/media/data-import/downloadables/8/a/c/abh_whatisafib-ucm_480465.pdf',
    },
  },
  {
    translationKey: 'heartDisease',
    urls: {
      en:
        'https://www.heart.org/-/media/data-import/downloadables/d/c/0/pe-abh-what-are-heart-disease-and-stroke-ucm_300313.pdf',
      es:
        'https://www.heart.org/-/media/data-import/downloadables/9/d/3/sp_cc_whathrtdiseasestrk_2016-ucm_316242.pdf',
    },
  },
  {
    translationKey: 'riskFactors',
    urls: {
      en:
        'https://www.heart.org/-/media/stroke-files/lets-talk-about-stroke/risk-factors/lets-talk-about-risk-factors-for-stroke-ucm_309713.pdf',
      es:
        'https://www.heart.org/-/media/data-import-2/2/b/9/riskfactors-for-stroke-spanish-ucm_310743.pdf',
    },
  },
  {
    translationKey: 'medicationTracker',
    urls: {
      en:
        'https://www.heart.org/-/media/files/health-topics/cardiac-rehab/pe-medicine-chart-ucm_445502.pdf',
      es:
        'https://www.heart.org/-/media/files/health-topics/cardiac-rehab/medicine-chart--spanish.pdf',
    },
  },
]

const Resource = flowMax(
  addTrackingProps(({linkText}) => ({
    name: linkText,
  })),
  ({url, linkText, translationKey}) => (
    <li css={styles.resourceContainer}>
      <Link to={url} external trackAs="Open wrap-up resource link">
        <AudioHighlight clipKey={translationKey}>{linkText}</AudioHighlight>
      </Link>
    </li>
  )
)

Resource.propTypes = {
  url: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
}

const Resources = flowMax(
  addTranslationHelpers,
  addCurrentLanguage,
  ({t, currentLanguage}) => (
    <ul css={styles.resourcesContainer}>
      {resources.map(({urls, translationKey}) => (
        <Resource
          url={urls[currentLanguage]}
          linkText={t(`wrapUpDonePage.resources.${translationKey}`)}
          translationKey={translationKey}
          key={translationKey}
        />
      ))}
    </ul>
  )
)

const AdditionalContent = () => (
  <div css={styles.additionalContentContainer}>
    <p css={styles.learnMore}>
      <AudioHighlight clipKey="learnMore" />
    </p>
    <Resources />
  </div>
)

const AdditionalContentAfterButton = flow(
  addTranslationHelpers,
  ({t}) => (
    <div css={styles.additionalContentAfterButtonContainer}>
      <p css={styles.disclaimer}>
        <AudioHighlight clipKey="disclaimer" />
      </p>
      <p css={styles.disclaimer}>{t('wrapUpDonePage.researchEvidence')}</p>
      <p css={styles.disclaimer}>
        <Trans i18nKey="wrapUpDonePage.docs" i18n={i18n}>
          <Link to="https://profiles.stanford.edu/paul-wang" external>
            link
          </Link>
          <Link to="https://profiles.stanford.edu/randall-stafford" external>
            link
          </Link>
        </Trans>
      </p>
      <p css={styles.disclaimer}>{t('wrapUpDonePage.textLevel')}</p>
    </div>
  )
)

const WrapUpDone = flowMax(
  addResetApp,
  addLogTrackingEvent,
  addHandlers({
    onButtonClick: ({resetApp, logTrackingEvent}) => () => {
      logTrackingEvent('Finish wrap-up')
      resetApp()
    },
  }),
  props => (
    <IntroPage
      {...props}
      translationKey="wrapUpDonePage"
      buttonKey="done"
      linkTo="/"
      questionKey="thanks"
      noHardy
      questionCss={styles.question}
      headerCss={styles.header}
      containerCss={styles.container}
      audioSectionId="wrapUpDonePage"
      additionalContent={<AdditionalContent />}
      additionalContentAfterButton={<AdditionalContentAfterButton />}
      name="Wrap-up done"
    />
  )
)

export default WrapUpDone

const styles = {
  question: {
    paddingRight: 36,
    marginBottom: 36,
  },
  header: {
    fontWeight: 600,
  },
  container: {
    paddingTop: 168,
  },
  disclaimer: {
    fontSize: 14,
    lineHeight: '20px',
    maxWidth: 480,
    marginTop: 21,
  },
  additionalContentContainer: {
    marginTop: -7,
  },
  additionalContentAfterButtonContainer: {
    marginTop: 72,
  },
  learnMore: {
    fontSize: 24,
    lineHeight: '32px',
    maxWidth: 620,
    marginBottom: 22,
  },
  resourcesContainer: {
    fontSize: 24,
    lineHeight: '32px',
    marginBottom: 58,
  },
  resourceContainer: {
    marginBottom: 12,
  },
}
