import React from 'react'
import {flow} from 'lodash/fp'

import ClinicianVideoPage from 'components/ClinicianVideoPage'
import {addTranslationHelpers} from 'util/i18n'
/* eslint-disable known-imports/no-unused-vars */
import balancingVideo from 'assets/videos/clinician-balancing-en-1080p.mp4'
import balancingVideoSpanish from 'assets/videos/clinician-balancing-es-1080p.mp4'
/* eslint-enable known-imports/no-unused-vars */
import balancingVideoCaptions from 'assets/videos/faq-video-captions-en.vtt'
import faqBalanceVideoCaptionsEnVietnamese from 'assets/videos/faqBalance-en-vi.vtt'
import faqBalanceVideoCaptionsEnFarsi from 'assets/videos/faqBalance-en-fa.vtt'
import faqBalanceVideoCaptionsEnTagalog from 'assets/videos/faqBalance-en-tl.vtt'
import faqBalanceVideoCaptionsEnRussian from 'assets/videos/faqBalance-en-ru.vtt'
import faqBalanceVideoCaptionsEnPortuguese from 'assets/videos/faqBalance-en-pt.vtt'
import faqBalanceVideoCaptionsEnKorean from 'assets/videos/faqBalance-en-ko.vtt'
import faqBalanceVideoCaptionsEnHindi from 'assets/videos/faqBalance-en-hi.vtt'
import faqBalanceVideoCaptionsEnGerman from 'assets/videos/faqBalance-en-de.vtt'
import faqBalanceVideoCaptionsEnFrench from 'assets/videos/faqBalance-en-fr.vtt'
import faqBalanceVideoCaptionsEnArabic from 'assets/videos/faqBalance-en-ar.vtt'
import balancingVideoCaptionsSpanish from 'assets/videos/faq-video-captions-es.vtt'
import faqBalanceVideoCaptionsEsVietnamese from 'assets/videos/faqBalance-es-vi.vtt'
import faqBalanceVideoCaptionsEsFarsi from 'assets/videos/faqBalance-es-fa.vtt'
import faqBalanceVideoCaptionsEsTagalog from 'assets/videos/faqBalance-es-tl.vtt'
import faqBalanceVideoCaptionsEsRussian from 'assets/videos/faqBalance-es-ru.vtt'
import faqBalanceVideoCaptionsEsPortuguese from 'assets/videos/faqBalance-es-pt.vtt'
import faqBalanceVideoCaptionsEsKorean from 'assets/videos/faqBalance-es-ko.vtt'
import faqBalanceVideoCaptionsEsHindi from 'assets/videos/faqBalance-es-hi.vtt'
import faqBalanceVideoCaptionsEsGerman from 'assets/videos/faqBalance-es-de.vtt'
import faqBalanceVideoCaptionsEsFrench from 'assets/videos/faqBalance-es-fr.vtt'
import faqBalanceVideoCaptionsEsArabic from 'assets/videos/faqBalance-es-ar.vtt'
import balancingVideoThumbnail from 'assets/images/clinician-balancing-video-thumbnail.png'

const ClinicianBalancing = flow(
  addTranslationHelpers,
  ({t}) => (
    <ClinicianVideoPage
      videoIdKey="clinicianBalancing"
      title={t('clinicianBalancingPage.videoTitle')}
      duration={40}
      pageKey="balancing"
      fallbackCacheKey="balancingVideo"
      fallbackCacheCaptionsUrl={{
        en: {
          en: balancingVideoCaptions,
          ar: faqBalanceVideoCaptionsEnArabic,
          fr: faqBalanceVideoCaptionsEnFrench,
          de: faqBalanceVideoCaptionsEnGerman,
          hi: faqBalanceVideoCaptionsEnHindi,
          ko: faqBalanceVideoCaptionsEnKorean,
          pt: faqBalanceVideoCaptionsEnPortuguese,
          ru: faqBalanceVideoCaptionsEnRussian,
          tl: faqBalanceVideoCaptionsEnTagalog,
          fa: faqBalanceVideoCaptionsEnFarsi,
          vi: faqBalanceVideoCaptionsEnVietnamese,
        },
        es: {
          es: balancingVideoCaptionsSpanish,
          ar: faqBalanceVideoCaptionsEsArabic,
          fr: faqBalanceVideoCaptionsEsFrench,
          de: faqBalanceVideoCaptionsEsGerman,
          hi: faqBalanceVideoCaptionsEsHindi,
          ko: faqBalanceVideoCaptionsEsKorean,
          pt: faqBalanceVideoCaptionsEsPortuguese,
          ru: faqBalanceVideoCaptionsEsRussian,
          tl: faqBalanceVideoCaptionsEsTagalog,
          fa: faqBalanceVideoCaptionsEsFarsi,
          vi: faqBalanceVideoCaptionsEsVietnamese,
        },
      }}
      fallbackCacheThumbnail={balancingVideoThumbnail}
      name="Video: balancing risks and benefits"
    />
  )
)

export default ClinicianBalancing
